import loadable from '@loadable/component';
import LoadingPage from "./components/LoadingPage";

export default [
    {
        name: "home",
        path: "/",
        component: loadable(() => import(`./pages/HomePage`), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "login",
        path: "/login",
        component: loadable(() => import('./pages/LoginPage'), { fallback: <LoadingPage /> }),
        exact: true,
    },
    {
        name: "backorder-options",
        path: "/backorder-options",
        component: loadable(() => import('./pages/BackorderOptionsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "domains.detail",
        path: "/domains/:domain",
        component: loadable(() => import('./pages/DomainDetailPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "domains.logs",
        path: "/domains/:domain/logs",
        component: loadable(() => import('./pages/LogPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "backorders",
        path: "/backorders",
        component: loadable(() => import('./pages/BackordersPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "backorders.create",
        path: "/backorders/create",
        component: loadable(() => import('./pages/BackorderCreatePage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "auctions",
        path: "/auctions",
        component: loadable(() => import('./pages/AuctionsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "closeouts",
        path: "/closeouts",
        component: loadable(() => import('./pages/CloseoutsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "portfolio",
        path: "/portfolio",
        component: loadable(() => import('./pages/PortfolioPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "orders",
        path: "/orders",
        component: loadable(() => import('./pages/OrdersPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "stats",
        path: "/stats",
        component: loadable(() => import('./pages/StatsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "sales",
        path: "/sales",
        component: loadable(() => import('./pages/SalesPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "inspects",
        path: "/inspects",
        component: loadable(() => import('./pages/InspectsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "inquiries",
        path: "/inquiries",
        component: loadable(() => import('./pages/InquiriesPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports",
        path: "/reports",
        component: loadable(() => import('./pages/reports/ReportsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports.roi",
        path: "/reports/roi",
        component: loadable(() => import('./pages/reports/RoiPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports.vendors",
        path: "/reports/vendors",
        component: loadable(() => import('./pages/reports/VendorsReportPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports.channels",
        path: "/reports/channels",
        component: loadable(() => import('./pages/reports/ChannelsReportPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports.tlds",
        path: "/reports/tlds",
        component: loadable(() => import('./pages/reports/TldsReportPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "reports.tlds.cashflow",
        path: "/reports/tlds/cashflow",
        component: loadable(() => import('./pages/reports/TldCashflowPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "sell",
        path: "/sell/:domain",
        component: loadable(() => import('./pages/SellPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "team",
        path: "/team",
        component: loadable(() => import('./pages/TeamPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "processors",
        path: "/processors",
        component: loadable(() => import('./pages/ProcessorsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "bidders",
        path: "/bidders",
        component: loadable(() => import('./pages/BiddersPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "transactions",
        path: "/transactions",
        component: loadable(() => import('./pages/TransactionsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "contacts",
        path: "/contacts",
        component: loadable(() => import('./pages/ContactsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "settings",
        path: "/settings",
        component: loadable(() => import('./pages/SettingsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "proxy.transactions",
        path: "/proxy/transactions",
        component: loadable(() => import('./pages/ProxyPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: false
    },
    {
        name: "screener",
        path: "/screener",
        component: loadable(() => import('./pages/screener/ScreenerHomePage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "screener:scans",
        path: "/screener/scans",
        component: loadable(() => import('./pages/screener/ScansPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "screener:importers",
        path: "/screener/importers",
        component: loadable(() => import('./pages/screener/ImportersPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "screener:snapshots",
        path: "/screener/snapshots",
        component: loadable(() => import('./pages/screener/SnapshotsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "screener:jvdomains",
        path: "/screener/jvdomains",
        component: loadable(() => import('./pages/screener/JVDomainsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: true
    },
    {
        name: "external:domains",
        path: "/external/domains",
        component: loadable(() => import('./pages/screener/ExternalDomainsPage'), { fallback: <LoadingPage /> }),
        exact: true,
        isPrivate: false
    },
    {
        name: "404",
        component: loadable(() => import('./pages/LoginPage'))
    }
];